<template>
  <div class="wrap">
    <van-tabs v-model:active="active" class="common-tabs">
      <van-tab title="体检报告">
        <div class="main">
          <div class="box" v-for="item in tjbg_list" :key="item.id">
            <div class="info">
              <div class="top">
                <div class="tag">报告日期</div>
                <div class="date">{{ item.bgrq }}</div>
              </div>
              <div class="thumb" v-if="!item.showMore">
                <div class="name">{{ item.bgnr }}</div>
              </div>
              <div class="detail" v-if="item.showMore">
                <div class="name">{{ item.bgnr }}</div>
                <div class="imgs">
                  <div class="img-wrap" v-for="(img, idx) in item.imgs" :key="idx" @click="handleClickImg(item, idx)">
                    <img :src="img" alt="" class="img">
                  </div>
                </div>
              </div>
            </div>
            <div class="box-btn" @click="handleClickItem(item)">
              <van-icon name="arrow-down" v-if="!item.showMore" color="#3fb6c6" size="18" />
              <van-icon name="arrow-up" v-else color="#3fb6c6" size="18" />
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="病历报告">
        <div class="main">
          <div class="box" v-for="item in blbg_list" :key="item.id">
            <div class="info">
              <div class="top">
                <div class="tag">报告日期</div>
                <div class="date">{{ item.bgrq }}</div>
              </div>
              <div class="thumb" v-if="!item.showMore">
                <div class="name">{{ item.bgnr }}</div>
              </div>
              <div class="detail" v-if="item.showMore">
                <div class="name">{{ item.bgnr }}</div>
                <div class="imgs">
                  <div class="img-wrap" v-for="(img, idx) in item.imgs" :key="idx" @click="handleClickImg(item, idx)">
                    <img :src="img" alt="" class="img">
                  </div>
                </div>
              </div>
            </div>
            <div class="box-btn" @click="handleClickItem(item)">
              <van-icon name="arrow-down" v-if="!item.showMore" color="#3fb6c6" size="18" />
              <van-icon name="arrow-up" v-else color="#3fb6c6" size="18" />
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
// @ is an alias to /src

import { showConfirmDialog, showImagePreview, showNotify } from 'vant';
import { mapActions, mapState } from 'vuex';
import axios from 'axios';
export default {
  name: 'Jkbg',
  data() {
    return {
      active: 0,
      tjbg_list: [],
      blbg_list: [],
    }
  },
  created() {
    this.getListData('tjbg');
    this.getListData('blbg');
  },
  computed: {
    ...mapState([]),
  },
  methods: {
    ...mapActions([]),
    handleClickImg(item, idx) {
      // console.log(item, idx);
      showImagePreview({
        images: [...item.imgs],
        closeable: true,
        startPosition: idx,
      });
    },
    handleClickItem(item) {
      item.showMore = !item.showMore;
      // this.list = this.list;
    },
    async getListData(bglx) {
      let { data: res } = await axios.get("/api/api/get_jkbg", {
        params: {
          sjhm: this.$route.query.sjhm,
          bglx: bglx
        }
      });
      res.data = res.data.map(m => ({
        ...m,
        imgs: m.bgtp ? m.bgtp.split(',') : [],
        showMore: false,
      }))
      this[bglx + '_list'] = res.data;
    }
  },

}
</script>
<style lang="less" scoped>
.wrap {
  .main {

    // margin-top: 24px;
    .box {
      margin: 12px;
      padding: 12px;
      background: #fff;
      border-radius: 10px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .info {
        flex: 1;
        overflow: hidden;

        .top {
          display: flex;
          align-items: center;

          .tag {
            background: #333;
            padding: 0 6px;
            border-radius: 3px;
            height: 20px;
            line-height: 20px;
            font-size: 14px;
            color: #e4b98b;
          }

          .date {
            margin-left: 6px;
            font-size: 14px;
            color: #666;
          }
        }

        .thumb {
          margin-top: 12px;

          .name {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 14px;
            color: #333;
            line-height: 1.5;
          }
        }

        .detail {
          margin-top: 12px;

          .name {
            font-size: 14px;
            color: #333;
            line-height: 1.2;
          }

          .imgs {
            margin-top: 12px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start;

            .img-wrap {
              flex: 0 1 auto;
              width: calc(25% - 6px);
              margin-right: 6px;
              margin-bottom: 6px;
              height: 0;
              position: relative;
              padding-top: calc(25% - 6px);
              border-radius: 10px;
              overflow: hidden;
            }

            .img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}
</style>
